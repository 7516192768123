import React from 'react';

import ReactTooltip from 'react-tooltip';
import {styled} from '../../theme';
import {ThemeDesigner} from '../../designer-theme';

interface TooltipProps {
  text: string;
}

const StyledSpan = styled.span`
  color: ${props => props.theme.colors.fontColor};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.buttonPrimaryColor};
  }
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  &.type-dark.place-top {
    background-color: ${props => props.theme.colors.infoPanelFilterBackground};
    color: ${props => props.theme.colors.infoPanelFilterFontColor};

    &:after {
      border-top-color: ${props =>
        props.theme.colors.infoPanelFilterBackground};
    }
  }
`;

const StyledTextContainer = styled.div`
  padding: 1px;
`;

export const Tooltip: React.FC<TooltipProps> = ({text}) => {
  return (
    <div>
      <StyledSpan
        data-tip
        data-for={`registerTip + ${text}`}
        className="material-symbols-outlined"
      >
        help
      </StyledSpan>

      <StyledReactTooltip
        arrowColor="transparent"
        place="top"
        id={`registerTip + ${text}`}
      >
        <StyledTextContainer>
          <span>{text}</span>
        </StyledTextContainer>
      </StyledReactTooltip>
    </div>
  );
};
