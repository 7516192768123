import * as React from 'react';
import {styled} from '../../theme';
import {ThemeDesigner} from '../../designer-theme';

interface Props {
  children?: React.ReactNode;
}

const WordWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: ${props => props.theme.colors.subheadingColor};
  padding-top: ${props => (props.theme === ThemeDesigner ? '24px' : '8px')};
  padding-bottom: 8px;
`;

const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: ${props => props.theme.colors.subheadingColor};
`;

export const WordLine: React.FC<Props> = ({children}) => (
  <WordWrapper>
    <Line />
    {children}
    <Line />
  </WordWrapper>
);
