import {Button, H3, PanelGroup} from '@zappar/foundry-react-components';
import * as React from 'react';
import {styled} from '../../theme';
import {atom, useAtom} from 'jotai';
import * as Jimp from 'jimp/browser/lib/jimp';
import {ERROR_ATOM, imageAtom} from './state';
import {trainButtonEnabledAtom} from '../../state';
import {ThemeDesigner} from '../../designer-theme';

const UploadForm = styled.form`
  display: flex;
  gap: 8px;
  ${props =>
    props.theme === ThemeDesigner
      ? `
    gap: 0;
  `
      : ''}
`;

const StyledButton = styled(Button)`
  max-width: 80px;
`;

const supportedFileTypes = ['image/jpeg', 'image/png'];

interface FileNameWrapperProps {
  error?: boolean;
}

const FileNameWrapper = styled.div<FileNameWrapperProps>`
  background-color: ${props => props.theme.colors.inputBackgroundColor};
  /* if error, outline the input in errorForeground */
  border: ${props => {
    if (props.error) {
      return `1px solid ${props.theme.colors.errorForeground}`;
    }
    return 'none';
  }};

  width: 200px;
  height: 24px;
  padding-left: 8px;
  display: flex;
  align-items: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${props =>
    props.theme === ThemeDesigner
      ? `
    box-sizing: border-box;
    border-radius: 8px;
    padding: 9px 15px;
    border: 1px solid #B2C4D7;
    height: 40px;
  `
      : ''}
`;

// todo: properly style the padding.
const MarginFix = styled.div`
  margin-left: -24px;
`;

const ThemedButton = styled(StyledButton)<{
  disabled: boolean;
  variant: 'inline' | 'primary' | 'secondary';
}>`
  ${props => {
    if (props.variant === 'primary') {
      return (
        props.theme === ThemeDesigner &&
        `background-color: ${props.disabled ? '#000000 !important' : '#4a90e2'};
      color: #FFFFFF;
      border-radius: 8px;
      padding: 10px 16px;
      margin-left: 16px;
      `
      );
    }
    if (props.variant === 'inline') {
      return (
        props.theme === ThemeDesigner &&
        `color: #344B60;
      `
      );
    }
  }};
`;

const StyledH3 = styled(H3)`
  text-transform: ${props =>
    props.theme === ThemeDesigner ? 'none' : 'uppercase'};
  ${props => {
    return props.theme === ThemeDesigner
      ? `
      color: #344B60;
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 8px;
  `
      : '';
  }}
`;

const StyledError = styled.label`
  color: ${props => props.theme.colors.errorForeground};
`;

const ErrorArea: React.FC<{error?: string}> = ({error}) => {
  return <StyledError>{error}</StyledError>;
};

export const UploadFormPanel = () => {
  const [error] = useAtom(ERROR_ATOM);
  const [image, setImage] = useAtom(imageAtom);
  const [trainButtonEnabled, setTrainButtonEnabled] = useAtom(
    trainButtonEnabledAtom
  );

  const maxCharactersInFileName = 32;

  const turnicateFileName = (fileName: string) => {
    if (fileName.length > maxCharactersInFileName) {
      const firstCharacters = fileName.substring(0, 12);
      const lastCharacters = fileName.substring(
        fileName.length - 12,
        fileName.length
      );
      return firstCharacters + '...' + lastCharacters;
    }
    return fileName;
  };

  const handleUploadClick = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/png,image/jpeg';
    document.body.appendChild(input);
    input.onchange = async (event: any) => {
      const file = event.target?.files?.[0];
      if (file && supportedFileTypes.includes(file.type)) {
        const jimpImage = await Jimp.read(await file.arrayBuffer());
        const width = jimpImage.getWidth(),
          height = jimpImage.getHeight();
        let resizeToWidth = 2048,
          resizeToHeight: number = Jimp.AUTO;
        if (height > width) {
          resizeToWidth = Jimp.AUTO;
          resizeToHeight = 2048;
        }
        const imageBuffer =
          height > 2048 || width > 2048
            ? await jimpImage
                .clone()
                .resize(resizeToWidth, resizeToHeight)
                .quality(95)
                .getBufferAsync(jimpImage.getMIME())
            : await jimpImage.getBufferAsync(Jimp.MIME_PNG);
        setImage({
          buffer: imageBuffer,
          base64: await jimpImage.getBase64Async(Jimp.MIME_PNG),
          width,
          height,
          name: file.name,
        });
        //! We can now enable the train button
        setTrainButtonEnabled(true);
      } else {
        setTrainButtonEnabled(false);
        setImage({
          buffer: undefined,
          base64: undefined,
          width: 0,
          height: 0,
          name: '...',
        });
      }
      document.body.removeChild(input);
    };
    input.click();
  };

  return (
    <MarginFix>
      <PanelGroup title={<StyledH3>Upload the image file</StyledH3>}>
        <UploadForm className="UploadForm">
          <FileNameWrapper error={!!error}>
            <label>{turnicateFileName(image.name)}</label>
          </FileNameWrapper>
          <ThemedButton
            variant="primary"
            disabled={false}
            onClick={handleUploadClick}
          >
            Browse
          </ThemedButton>
        </UploadForm>
        <ErrorArea error={error} />
        <label>Supported file formats: PNG, JPEG</label>
      </PanelGroup>
    </MarginFix>
  );
};
