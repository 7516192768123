var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import * as Units from "./units";
function parseOptions(fileOrData, Jimp, options) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const opts = {
            maxWidth: (_a = options === null || options === void 0 ? void 0 : options.maxWidth) !== null && _a !== void 0 ? _a : 500,
            maxHeight: (_b = options === null || options === void 0 ? void 0 : options.maxHeight) !== null && _b !== void 0 ? _b : 500,
            excludePreview: (_c = options === null || options === void 0 ? void 0 : options.excludePreview) !== null && _c !== void 0 ? _c : false,
            topRadius: 0,
            bottomRadius: 0,
            sideLength: 0,
            physicalScaleFactor: -1,
            onTrainProgress: options === null || options === void 0 ? void 0 : options.onTrainProgress,
        };
        if (!checkOnlyOneSet(options === null || options === void 0 ? void 0 : options.inputWidth, options === null || options === void 0 ? void 0 : options.inputHeight, options === null || options === void 0 ? void 0 : options.dpi)) {
            throw new Error("Set only one of inputWidth, inputHeight or dpi");
        }
        if (!checkOnlyOneSet(options === null || options === void 0 ? void 0 : options.radius, options === null || options === void 0 ? void 0 : options.circumference, options === null || options === void 0 ? void 0 : options.topRadius)) {
            throw new Error("Set only one of radius, topRadius, or circumference");
        }
        if (!checkOnlyOneSet(options === null || options === void 0 ? void 0 : options.radius, options === null || options === void 0 ? void 0 : options.circumference, options === null || options === void 0 ? void 0 : options.bottomRadius)) {
            throw new Error("Set only one of radius, bottomRadius, or circumference");
        }
        // Jimp read seems to have incorrect parameter types. Avoid casting as any:
        const image = yield (typeof fileOrData === "string" ? Jimp.read(fileOrData) : Jimp.read(Buffer.from(fileOrData)));
        const width = image.getWidth();
        const height = image.getHeight();
        if (!width || !height)
            throw new Error("Unable to discover width and height of image");
        if (width < 128 || height < 128)
            throw new Error("Image too small (width and/or height < 128px)");
        if (!(options === null || options === void 0 ? void 0 : options.dpi) && ((options === null || options === void 0 ? void 0 : options.inputWidth) || (options === null || options === void 0 ? void 0 : options.inputHeight))) {
            options.dpi = Units.calculateDPI(options.inputWidth, options.inputHeight, width, height);
        }
        opts.physicalScaleFactor = Units.getPhysicalScaleFactor({ dpi: options === null || options === void 0 ? void 0 : options.dpi, heightPixels: height });
        const _sideLength = Units.getValueInTargetUnits(options === null || options === void 0 ? void 0 : options.sideLength, height, options === null || options === void 0 ? void 0 : options.dpi);
        const radius = Units.getValueInTargetUnits(options === null || options === void 0 ? void 0 : options.radius, height, options === null || options === void 0 ? void 0 : options.dpi);
        const _topRadius = Units.getValueInTargetUnits(options === null || options === void 0 ? void 0 : options.topRadius, height, options === null || options === void 0 ? void 0 : options.dpi);
        const _bottomRadius = Units.getValueInTargetUnits(options === null || options === void 0 ? void 0 : options.bottomRadius, height, options === null || options === void 0 ? void 0 : options.dpi);
        const circumference = Units.getValueInTargetUnits(options === null || options === void 0 ? void 0 : options.circumference, height, options === null || options === void 0 ? void 0 : options.dpi);
        opts.topRadius = _topRadius || radius || circumference / (2 * Math.PI) || 0;
        opts.bottomRadius = _bottomRadius || radius || circumference / (2 * Math.PI) || 0;
        opts.sideLength = _sideLength || 0;
        const buffer = yield image.getBufferAsync("image/png");
        let preview;
        if (!opts.excludePreview) {
            const p = yield (typeof fileOrData === "string" ? Jimp.read(fileOrData) : Jimp.read(Buffer.from(fileOrData)));
            p.scaleToFit(320, 320);
            preview = yield p.getBufferAsync("image/jpeg");
        }
        const { maxWidth, maxHeight, topRadius, bottomRadius, sideLength, physicalScaleFactor, onTrainProgress } = opts;
        return { buffer, preview, maxWidth, maxHeight, topRadius, bottomRadius, sideLength, physicalScaleFactor, onTrainProgress };
    });
}
function checkOnlyOneSet(...args) {
    let foundOne = false;
    for (const a of args) {
        if (a !== undefined) {
            if (foundOne)
                return false; // More than one set
            foundOne = true;
        }
    }
    return true;
}
export default parseOptions;
