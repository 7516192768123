/* eslint-disable node/no-extraneous-import */
import {useAtom} from 'jotai';
import * as React from 'react';
import {image_target_type_t_atom} from '../../state';
import {
  NumericInput,
  StyledInputFiller,
  StyledInputsContainer,
} from '../components/input';
import {sideLengthAtom} from './state';
import {image_target_type_t} from '@zappar/zappar-cv';

export const SideLengthPanel = () => {
  const [sideLength, setSideLength] = useAtom(sideLengthAtom);
  const [targetType] = useAtom(image_target_type_t_atom);

  if (targetType !== image_target_type_t.IMAGE_TRACKER_TYPE_CONICAL)
    return null;

  return (
    <StyledInputsContainer>
      <NumericInput
        onChange={setSideLength}
        value={sideLength}
        title="Side of label"
        showUnit
        tooltip="The length of the slanted edge of the cone"
      />
      <StyledInputFiller />
    </StyledInputsContainer>
  );
};
