import {atom, useAtom, useAtomValue} from 'jotai';

export enum CircumferenceOrRadius {
  CIRCUMFERENCE = 'Circumference',
  RADIUS = 'Radius',
}

interface DimensionAndValue {
  dimension: CircumferenceOrRadius;
  value: number;
}

const topValue = 135;
const bottomValue = 135;

export const topDimensionAtom = atom<DimensionAndValue>({
  dimension: CircumferenceOrRadius.CIRCUMFERENCE,
  value: topValue,
});

export const bottomDimensionAtom = atom<DimensionAndValue>({
  dimension: CircumferenceOrRadius.CIRCUMFERENCE,
  value: bottomValue,
});

export const useResetDimensionAtoms = () => {
  const [topDimension, setTopDimension] = useAtom(topDimensionAtom);
  const [bottomDimension, setBottomDimension] = useAtom(bottomDimensionAtom);

  function reset() {
    setTopDimension({
      dimension: CircumferenceOrRadius.CIRCUMFERENCE,
      value: topValue,
    });

    setBottomDimension({
      dimension: CircumferenceOrRadius.CIRCUMFERENCE,
      value: bottomValue,
    });
  }

  return reset;
};

export const useTopAndBottomRadius = () => {
  const topDimension = useAtomValue(topDimensionAtom);
  const bottomDimension = useAtomValue(bottomDimensionAtom);

  return {
    top:
      topDimension.dimension === CircumferenceOrRadius.RADIUS
        ? topDimension.value
        : topDimension.value / (2 * Math.PI),
    bottom:
      bottomDimension.dimension === CircumferenceOrRadius.RADIUS
        ? bottomDimension.value
        : bottomDimension.value / (2 * Math.PI),
  };
};
