import * as React from 'react';
import {useCurrentRoute, Routes} from '../../state';
import {styled} from '../../theme';

type SplitterProps = {
  leftElement: React.ReactNode;
  rightElement: React.ReactNode;
};

export const Splitter: React.FC<SplitterProps> = ({
  leftElement,
  rightElement,
}) => {
  const currentRoute = useCurrentRoute();
  const fullScreen = currentRoute === Routes.TargetTypeSelection;

  return (
    <SplitContainer className="splitter">
      <Pane className="pane-l" fullScreen={fullScreen}>
        {leftElement}
      </Pane>
      <Pane className="pane-r" hidden={fullScreen}>
        {rightElement}
      </Pane>
    </SplitContainer>
  );
};

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const Pane = styled.div<{fullScreen?: boolean; hidden?: boolean}>`
  display: ${({hidden}) => (hidden ? 'none' : 'flex')};
  flex: 1;
  max-width: ${({fullScreen}) => (fullScreen ? '100%' : '50%')};
`;
