import {atom, useAtom} from 'jotai';
// eslint-disable-next-line node/no-extraneous-import
import {image_target_type_t} from '@zappar/zappar-cv';

import {TrainOptions} from '@zappar/imagetraining/lib/options';
import {Dimension, dimensionAtom, unit_atom} from './sections/dimensions/state';
import {sideLengthAtom} from './sections/sidelength/state';
import {useTopAndBottomRadius} from './sections/shape/state';
import {ERROR_ATOM, imageAtom} from './sections/upload/state';
import {parseOptions} from './sections/preview/parser';

export const image_target_type_t_atom = atom<image_target_type_t>(
  image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR
);

export const trainButtonEnabledAtom = atom<boolean>(false);

export enum Routes {
  TargetTypeSelection = 'TargetTypeSelection',
  TargetParameters = 'TargetParameters',

  Training = 'Training',
  Finished = 'Finished',
}

export const currentRouteAtom = atom<Routes>(Routes.TargetTypeSelection);

export const useCurrentRoute = () => {
  const [currentRoute] = useAtom(currentRouteAtom);
  return currentRoute;
};

/**
 * Returns a function that can be used to navigate to the next route.
 */
export const useNextRoute = () => {
  const [currentRoute, setCurrentRoute] = useAtom(currentRouteAtom);
  const nextRoute = () => {
    switch (currentRoute) {
      case Routes.TargetTypeSelection:
        setCurrentRoute(Routes.TargetParameters);
        break;
      case Routes.TargetParameters:
        setCurrentRoute(Routes.Training);
        break;

      case Routes.Training:
        setCurrentRoute(Routes.Finished);
        break;
    }
  };

  return nextRoute;
};

/**
 * Returns a function that can be used to navigate to the previous route.
 */
export const usePreviousRoute = () => {
  const [currentRoute, setCurrentRoute] = useAtom(currentRouteAtom);

  const previousRoute = () => {
    switch (currentRoute) {
      case Routes.TargetParameters:
        setCurrentRoute(Routes.TargetTypeSelection);
        break;
      case Routes.Training:
        setCurrentRoute(Routes.TargetParameters);
        break;
    }
  };

  return previousRoute;
};

export function useTrainParameters() {
  const type = useAtom(image_target_type_t_atom)[0];
  const [unit] = useAtom(unit_atom);
  const [dimensions] = useAtom(dimensionAtom);
  const [sideLength] = useAtom(sideLengthAtom);
  const radius = useTopAndBottomRadius();
  const [image] = useAtom(imageAtom);

  const previewMeshOptions: Partial<TrainOptions> = {
    inputHeight:
      dimensions.dimension === Dimension.HEIGHT
        ? `${dimensions.value}${unit}`
        : undefined,
    inputWidth:
      dimensions.dimension === Dimension.WIDTH
        ? `${dimensions.value}${unit}`
        : undefined,
    topRadius:
      type !== image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR
        ? `${radius.top}${unit}`
        : undefined,
    bottomRadius:
      type !== image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR
        ? `${radius.bottom}${unit}`
        : undefined,
    sideLength:
      type === image_target_type_t.IMAGE_TRACKER_TYPE_CONICAL
        ? `${sideLength}${unit}`
        : undefined,
  };

  if (!image.base64) return null;

  return {
    image: image.base64,
    options: previewMeshOptions,
  };
}

export const useGetPreviewOptions = () => {
  const type = useAtom(image_target_type_t_atom)[0];
  const [unit] = useAtom(unit_atom);
  const [dimensions] = useAtom(dimensionAtom);
  const [sideLength] = useAtom(sideLengthAtom);
  const radius = useTopAndBottomRadius();
  const [image, setImage] = useAtom(imageAtom);
  const [error, setError] = useAtom(ERROR_ATOM);

  const previewMeshOptions: Partial<TrainOptions> = {
    inputHeight:
      dimensions.dimension === Dimension.HEIGHT
        ? `${dimensions.value}${unit}`
        : undefined,
    inputWidth:
      dimensions.dimension === Dimension.WIDTH
        ? `${dimensions.value}${unit}`
        : undefined,
    topRadius:
      type !== image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR
        ? `${radius.top}${unit}`
        : undefined,
    bottomRadius:
      type !== image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR
        ? `${radius.bottom}${unit}`
        : undefined,
    sideLength:
      type === image_target_type_t.IMAGE_TRACKER_TYPE_CONICAL
        ? `${sideLength}${unit}`
        : undefined,
  };

  if (!image.base64) return null;

  // TODO:
  //!! Error handling needs to happen here.
  try {
    const opts = parseOptions(previewMeshOptions, {
      w: image.width,
      h: image.height,
    });
    setError('');

    return opts;
  } catch (e) {
    setError(e.message);
    console.error(e);
  }

  return null;
};
