import {useAtom} from 'jotai';
import * as React from 'react';

import {image_target_type_t_atom, Routes, useCurrentRoute} from '../state';
import {CardGroup} from './components/cards';
// eslint-disable-next-line node/no-extraneous-import
import {image_target_type_t} from '@zappar/zappar-cv';
// @ts-ignore
import conical_icon from '../assets/conical.png';
// @ts-ignore
import flat_icon from '../assets/flat.png';
// @ts-ignore
import curved_icon from '../assets/curved.png';
import {styled} from '../theme';
import {useTheme} from 'styled-components';
import {ThemeDesigner} from '../designer-theme';

const StyledCards = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 20px;
`;

export const TargetTypeSelection: React.FC = React.memo(() => {
  const currentRoute = useCurrentRoute();
  const theme = useTheme();
  const [targetType, setTargetType] = useAtom(image_target_type_t_atom);

  if (currentRoute !== Routes.TargetTypeSelection) return null;

  return (
    <StyledCards>
      <CardGroup onChange={setTargetType} defaultValue={targetType}>
        <CardGroup.Card
          value={image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR}
          title="Flat"
          description="For flat surfaces such as books and posters."
          image={<img src={flat_icon} />}
        />
        <CardGroup.Card
          value={image_target_type_t.IMAGE_TRACKER_TYPE_CYLINDRICAL}
          title="Curved"
          description="For curved surfaces such as wine bottles."
          image={<img src={curved_icon} />}
        />
        {theme !== ThemeDesigner && (
          <CardGroup.Card
            value={image_target_type_t.IMAGE_TRACKER_TYPE_CONICAL}
            title="Conical"
            description="For conical surfaces such as coffee cups."
            image={<img src={conical_icon} />}
          />
        )}
      </CardGroup>
    </StyledCards>
  );
});
