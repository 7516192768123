import {useAtom} from 'jotai';
import React from 'react';
import {styled} from '../../theme';

import {unit_atom, UNIT} from '../dimensions/state';

type ButtonProps = {
  active?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const StyledButton = styled.button<ButtonProps>`
  font-weight: bold;
  font-size: 14px;
  color: ${props => (props.active ? '#4A90E2' : '#B4BBC3')};
  border: 0;
  background: none;
  cursor: pointer;
`;

const StyledLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 43px;
  height: 24px;
  padding: 0px;
  opacity: 1;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

const StyledSpan = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 13px;
  :before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const StyledInput = styled.input`
  :checked + ${StyledSpan} {
    background-color: #4a90e2;
  }
  :focus + ${StyledSpan} {
    box-shadow: 0 0 1px #b4bbc3;
  }
  :checked + ${StyledSpan}:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
  }
`;

export const Toggle = () => {
  const [unit, setUnit] = useAtom(unit_atom);

  return (
    <div id="toggle">
      <StyledButton active={unit === UNIT.MM} onClick={() => setUnit(UNIT.MM)}>
        Metric
      </StyledButton>
      <StyledLabel>
        <StyledInput
          type="checkbox"
          onChange={
            unit === UNIT.IN ? () => setUnit(UNIT.MM) : () => setUnit(UNIT.IN)
          }
          checked={unit === UNIT.IN}
        />
        <StyledSpan />
      </StyledLabel>
      <StyledButton active={unit === UNIT.IN} onClick={() => setUnit(UNIT.IN)}>
        Imperial
      </StyledButton>
    </div>
  );
};
