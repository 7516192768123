import * as THREE from 'three';
import React from 'react';

import {useAtom} from 'jotai';
import {imageAtom} from '../upload/state';

function useUserUploadedMaterial() {
  const [texture, setTexture] = React.useState<THREE.Texture | null>(null);
  const [image] = useAtom(imageAtom);

  const material = React.useMemo(() => {
    if (!texture) {
      return new THREE.MeshBasicMaterial({
        color: 'white',
        side: THREE.DoubleSide,
      });
    }
    return new THREE.MeshBasicMaterial({map: texture, side: THREE.DoubleSide});
  }, [texture]);

  const loader = React.useMemo(() => new THREE.TextureLoader(), []);

  // TODO: memo
  React.useEffect(() => {
    if (!image.base64) return;

    const texture = loader.load(image.base64);
    texture.encoding = THREE.sRGBEncoding;
    setTexture(texture);

    return () => {
      material.dispose();
      texture?.dispose();
    };
  }, [image.base64]);

  return material;
}

export const UserUploadedMaterial = (): JSX.Element => {
  const material = useUserUploadedMaterial();
  return <primitive object={material} />;
};
