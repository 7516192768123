import React from 'react';
import styled from 'styled-components';

export interface RadioOption {
  value: string;
  name: string;
  label: string;
}

interface IParentProps {
  radioOptions: RadioOption[];
  value: unknown;
  onChange: (v: unknown) => unknown;
}

const StyledLabel = styled.label`
  height: 16px;
  line-height: 16px;
  margin: 0;
  padding: 0;
`;

const StyledDiv = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-basis: content;
  margin-right: 12px;
  input {
    height: 16px;
    margin: 0 4px 0 0;
    padding: 0;
    accent-color: #4a90e2;
  }
`;

export const RadioGroup: React.FunctionComponent<IParentProps> = ({
  radioOptions,
  onChange,
  value,
}) => {
  const _onChange = (value: unknown) => {
    onChange(value);
  };

  const options = radioOptions.map(radioInput => {
    return (
      <StyledDiv
        key={radioInput.label}
        onClick={() => _onChange(radioInput.value)}
      >
        <input
          type="radio"
          name={radioInput.name}
          value={radioInput.value}
          checked={value === radioInput.value}
          readOnly
        />
        <StyledLabel htmlFor={radioInput.name}>{radioInput.label}</StyledLabel>
      </StyledDiv>
    );
  });

  return <>{options}</>;
};
