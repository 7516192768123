import {atom} from 'jotai';

export const imageAtom = atom<{
  buffer?: ArrayBuffer;
  base64?: string;
  width?: number;
  height?: number;
  name: string;
}>({
  name: '...',
});

export const ERROR_ATOM = atom<string>('');
