import * as React from 'react';
import {Routes, useCurrentRoute} from '../state';
import {styled} from '../theme';
import {UploadFormPanel} from './upload/upload';
import {DimensionsOfImageFilePanel} from './dimensions/dimensions';
import {ShapeOfObjectPanel} from './shape/shape';
import {SideLengthPanel} from './sidelength/sidelength';

const StyledParameters = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;
  width: 100%;
`;

export const Parameters: React.FC = () => {
  const currentRoute = useCurrentRoute();

  if (currentRoute === Routes.TargetTypeSelection) return null;

  return (
    <StyledParameters className="parameters">
      <UploadFormPanel />
      <DimensionsOfImageFilePanel />
      <ShapeOfObjectPanel />
      <SideLengthPanel />
    </StyledParameters>
  );
};
