import {RadioSwitcher, RadioSwitch} from '@zappar/foundry-react-components';
import {useAtom} from 'jotai';
import React from 'react';
import {styled} from '../../theme';

import {unit_atom, UNIT} from '../dimensions/state';
import {StyledRadioSwitcher} from './input';

const StyledUnitSwitcher = styled.div`
  display: flex;
`;

export const UnitSwitcher = () => {
  const [unit, setUnit] = useAtom(unit_atom);

  return (
    <StyledUnitSwitcher>
      <StyledRadioSwitcher className="cn">
        <RadioSwitch
          checked={unit === UNIT.MM}
          onChecked={() => {
            setUnit(UNIT.MM);
          }}
          icon={<>Metric</>}
        />
        <RadioSwitch
          checked={unit === UNIT.IN}
          onChecked={() => {
            setUnit(UNIT.IN);
          }}
          icon={<>Imperial</>}
        />
      </StyledRadioSwitcher>
    </StyledUnitSwitcher>
  );
};
