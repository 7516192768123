/* eslint-disable node/no-extraneous-import */
import {H1} from '@zappar/foundry-react-components';
import {image_target_type_t} from '@zappar/zappar-cv';
import {useAtom} from 'jotai';
import * as React from 'react';
import {useCurrentRoute, image_target_type_t_atom, Routes} from '../state';
import {styled} from '../theme';
import {UnitSwitcher} from './components/unitswitcher';
import {useTheme} from 'styled-components';
import {ThemeDesigner} from '../designer-theme';
import {Toggle} from './components/toggle';

function mapTrackingTypeToString(type: image_target_type_t): string {
  switch (type) {
    case image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR:
      return 'Flat';
    case image_target_type_t.IMAGE_TRACKER_TYPE_CYLINDRICAL:
      return 'Curved';
    case image_target_type_t.IMAGE_TRACKER_TYPE_CONICAL:
      return 'Conical';
    default:
      return '';
  }
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${props => {
    return (
      props.theme === ThemeDesigner &&
      `
      #toggle {
        margin-bottom: 4px; // should be 12px but https://gitlab.com/zappar/platform-libs/foundry-react-components/-/blob/master/src/components/panelGroup/PanelGroup.tsx contains 8px top padding (non accessible)
      }
      `
    );
  }};
`;

const ThemedH1 = styled(H1)`
  ${props => {
    return (
      props.theme === ThemeDesigner &&
      `font-size: 28px;
      font-weight: 700;
      font-style: normal;
      color: #344B60;
      line-height: 32px;
      margin-bottom: 16px;  // should be 24px but https://gitlab.com/zappar/platform-libs/foundry-react-components/-/blob/master/src/components/panelGroup/PanelGroup.tsx contains 8px top padding (non accessible)
      `
    );
  }};
`;

const ThemedDiv = styled.div`
  ${props => {
    return (
      props.theme === ThemeDesigner &&
      `
      margin-top: 8px;  // should be 0px but https://gitlab.com/zappar/platform-libs/foundry-react-components/-/blob/master/src/components/panelGroup/PanelGroup.tsx contains 8px top padding (non accessible)
      `
    );
  }};
`;

export const Header: React.FC = () => {
  const currentRoute = useCurrentRoute();
  const theme = useTheme();
  const [targetType] = useAtom(image_target_type_t_atom);

  const [title, description] = React.useMemo(() => {
    switch (currentRoute) {
      case Routes.TargetTypeSelection:
        return [
          'Choose image type',
          'Which image tracking type would you like to use?',
        ];
      case Routes.TargetParameters:
      case Routes.Training:
        return [`${mapTrackingTypeToString(targetType)} target image`, ''];
      default:
        return ['', ''];
    }
  }, [currentRoute, targetType]);

  return (
    <HeaderContainer>
      <div>
        {!!title && <ThemedH1>{title}</ThemedH1>}
        {!!description && <ThemedDiv>{description}</ThemedDiv>}
      </div>

      {(currentRoute === Routes.TargetParameters ||
        currentRoute === Routes.Training ||
        currentRoute === Routes.Finished) &&
        (theme === ThemeDesigner ? <Toggle /> : <UnitSwitcher />)}
    </HeaderContainer>
  );
};
