/* eslint-disable node/no-extraneous-import */
import {InputForType, StyledSelect} from '@zappar/foundry-react-components';
import {useAtom} from 'jotai';
import * as React from 'react';
import {image_target_type_t_atom} from '../../state';
import {styled} from '../../theme';
import {
  StyledInputsContainer,
  RadioInput,
  StyledInputFiller,
  NumericInput,
} from '../components/input';
import {image_target_type_t} from '@zappar/zappar-cv';

import {
  bottomDimensionAtom,
  CircumferenceOrRadius,
  useResetDimensionAtoms,
  topDimensionAtom,
} from './state';
import {WordLine} from '../components/WordLine';
import {ThemeDesigner} from '../../designer-theme';
import {RadioGroup} from '../components/radiogroup';
import {useTheme} from 'styled-components';

const StyledShapeOfObjectPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ThemedStyledInputsContainer = styled(StyledInputsContainer)`
  ${props => {
    if (props.theme === ThemeDesigner) {
      return `
      justify-content: flex-start;
      gap: 2px;
      margin-bottom: 12px;
    `;
    }
  }}
`;

// TODO: This state can now be simplified to just one atom.
export const ShapeOfObjectPanel = () => {
  const [topDimension, setTopDimension] = useAtom(topDimensionAtom);
  const [bottomDimension, setBottomDimension] = useAtom(bottomDimensionAtom);
  const [targetType] = useAtom(image_target_type_t_atom);
  const reset = useResetDimensionAtoms();
  const theme = useTheme();

  React.useEffect(() => {
    reset();
  }, [targetType]);

  const handleDimensionChange = (dimension: CircumferenceOrRadius) => {
    setTopDimension({
      dimension,
      value: topDimension.value,
    });

    setBottomDimension({
      dimension: dimension,
      value: bottomDimension.value,
    });
  };

  const handleTopValueChange = (value: number) => {
    setTopDimension({
      dimension: topDimension.dimension,
      value: value,
    });
  };

  const handleBottomValueChange = (value: number) => {
    setBottomDimension({
      dimension: bottomDimension.dimension,
      value,
    });
  };

  // if true, render only single input and set both, top and bottom, to the same value.
  const singleInput =
    targetType === image_target_type_t.IMAGE_TRACKER_TYPE_CYLINDRICAL;

  const Inputs = React.useMemo(() => {
    if (singleInput) {
      return (
        <>
          <NumericInput
            tooltip="The distance around the object"
            showUnit
            title={`${topDimension.dimension}`}
            value={topDimension.value}
            onChange={value => {
              handleTopValueChange(value);
              handleBottomValueChange(value);
            }}
          />
          <StyledInputFiller />
        </>
      );
    } else {
      return (
        <>
          <NumericInput
            showUnit
            tooltip="The distance around the top of the object"
            title={`Top ${topDimension.dimension}`}
            value={topDimension.value}
            onChange={handleTopValueChange}
          />
          <NumericInput
            tooltip="The distance around the bottom of the object"
            showUnit
            title={`Bottom ${bottomDimension.dimension}`}
            value={bottomDimension.value}
            onChange={handleBottomValueChange}
          />
        </>
      );
    }
  }, [topDimension, bottomDimension, singleInput]);

  if (targetType === image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR) return null;

  return (
    <StyledShapeOfObjectPanel>
      <WordLine>Object</WordLine>
      <ThemedStyledInputsContainer>
        {theme !== ThemeDesigner ? (
          <>
            <RadioInput
              tooltip="The distance around the object"
              onChange={handleDimensionChange}
              options={['Circumference', 'Radius']}
              value={topDimension.dimension}
            />
            <StyledInputFiller />
          </>
        ) : (
          <RadioGroup
            onChange={handleDimensionChange}
            value={topDimension.dimension}
            radioOptions={[
              {
                name: 'Radius',
                value: 'Radius',
                label: 'Radius',
              },
              {
                name: 'Circumference',
                value: 'Circumference',
                label: 'Circumference',
              },
            ]}
          />
        )}
      </ThemedStyledInputsContainer>

      <StyledInputsContainer>{Inputs}</StyledInputsContainer>
    </StyledShapeOfObjectPanel>
  );
};
