/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable import/no-webpack-loader-syntax */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as Jimp from "jimp/browser/lib/jimp";
import * as Comlink from "comlink";
import parseOptions from "../options";
import { VERSION } from "../version";
console.log(`Zappar ImageTraining v${VERSION}`);
let _Worker;
export function ___setWorker(worker) {
    _Worker = worker;
}
function perform(opts) {
    return __awaiter(this, void 0, void 0, function* () {
        const { buffer: inputData, preview: previewData, maxWidth, maxHeight, topRadius, bottomRadius, sideLength, physicalScaleFactor, onTrainProgress, } = opts;
        const Wrapper = Comlink.wrap(_Worker);
        const imageTrainer = yield new Wrapper();
        yield imageTrainer.initializeWeb();
        const instance = imageTrainer.instance;
        yield instance.setInputData(inputData);
        if (previewData)
            yield instance.setPreviewData(previewData, true);
        if (onTrainProgress)
            instance.setOnProgressCallback(onTrainProgress);
        yield instance.setMaxValues(maxWidth, maxHeight);
        instance.setCurved(topRadius, bottomRadius, sideLength);
        instance.setPhysicalScaleFactor(physicalScaleFactor);
        yield instance.train();
        const res = (yield instance.getOutputData()).slice();
        yield instance.delete();
        if (onTrainProgress)
            onTrainProgress(100);
        return res;
    });
}
export function train(fileOrData, options) {
    return __awaiter(this, void 0, void 0, function* () {
        const opts = yield parseOptions(fileOrData, Jimp, options);
        return perform(opts);
    });
}
