/* eslint-disable node/no-extraneous-import */
import {useAtom} from 'jotai';
import * as React from 'react';
import {image_target_type_t_atom} from '../../state';
import {
  NumericInput,
  SelectInput,
  StyledInputFiller,
  StyledInputsContainer,
} from '../components/input';
import {image_target_type_t} from '@zappar/zappar-cv';
import {Dimension, dimensionAtom} from './state';
import {H3} from '@zappar/foundry-react-components';
import {WordLine} from '../components/WordLine';
import styled, {useTheme} from 'styled-components';
import {ThemeDesigner} from '../../designer-theme';

const ThemedH3 = styled(H3)<{active?: boolean}>`
  ${props => {
    return (
      props.theme === ThemeDesigner &&
      `font-size: 16px;
      font-weight: 700;
      font-style: normal;
      color: #344B60;
      line-height: 24px;
      margin-top: 24px;
      margin-bottom: 4px;
      text-transform: none;
      `
    );
  }};
`;

export const DimensionsOfImageFilePanel = () => {
  const [dimensions, setDimensions] = useAtom(dimensionAtom);
  const [targetType] = useAtom(image_target_type_t_atom);
  const theme = useTheme();

  const optionalDimensions =
    targetType === image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR;

  const handleDimensionChange = dimension => {
    setDimensions(prevDimensions => ({...prevDimensions, dimension}));
  };

  const handleValueChange = value => {
    setDimensions(prevDimensions => ({...prevDimensions, value}));
  };

  const options = React.useMemo(() => {
    return targetType === image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR
      ? theme === ThemeDesigner
        ? [Dimension.HEIGHT, Dimension.WIDTH]
        : [Dimension.HEIGHT, Dimension.WIDTH, Dimension.UNKNOWN]
      : [Dimension.HEIGHT, Dimension.WIDTH];
  }, [targetType]);

  return (
    <div>
      <ThemedH3>
        Image file
        {targetType === image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR &&
        theme === ThemeDesigner
          ? ' '
          : ' and object '}
        dimensions {optionalDimensions && '(optional)'}
      </ThemedH3>
      <label>
        These measurements allow us to calibrate our image tracking.
      </label>

      {!(
        theme === ThemeDesigner &&
        targetType === image_target_type_t.IMAGE_TRACKER_TYPE_PLANAR
      ) ? (
        <WordLine>Image</WordLine>
      ) : (
        <div style={{marginTop: '16px'}}></div>
      )}

      <StyledInputsContainer>
        <SelectInput
          title="Choose side to measure"
          value={dimensions.dimension}
          onChange={handleDimensionChange}
          options={options}
        />
        {dimensions.dimension !== Dimension.UNKNOWN ? (
          <NumericInput
            title={`${dimensions.dimension} of image`}
            showUnit
            onChange={handleValueChange}
            value={dimensions.value}
          />
        ) : (
          <StyledInputFiller />
        )}
      </StyledInputsContainer>
    </div>
  );
};
