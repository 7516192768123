/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable node/no-unsupported-features/node-builtins */
import {Button} from '@zappar/foundry-react-components';
import {useAtom} from 'jotai';
import * as React from 'react';
import {
  useNextRoute,
  usePreviousRoute,
  useCurrentRoute,
  Routes,
  trainButtonEnabledAtom,
  useTrainParameters,
  image_target_type_t_atom,
} from '../state';
// eslint-disable-next-line node/no-extraneous-import
import {image_target_type_t} from '@zappar/zappar-cv';
import {styled} from '../theme';
import {train} from '@zappar/imagetraining/lib/web';
import {ERROR_ATOM, imageAtom} from './upload/state';
import {isMattercraft} from '../index';
import {ThemeDesigner} from '../designer-theme';
import {parseOptions} from './preview/parser';

const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

const BackText = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
`;

const ThemedButton = styled(Button)<{
  disabled: boolean;
  variant: 'inline' | 'primary' | 'secondary';
}>`
  ${props => {
    if (props.variant === 'primary') {
      return (
        props.theme === ThemeDesigner &&
        `
      background-color: #4a90e2;
      color: #FFFFFF;
      border-radius: 8px;
      padding: 9px 15px;
      height: 40px;
      `
      );
    }
    if (props.variant === 'inline') {
      return (
        props.theme === ThemeDesigner &&
        `color: #344B60;
      `
      );
    }
  }};
`;

interface FileGeneratorMessage {
  t: 'znewfile';
  source: 'framed-image-trainer';
  designerData?: {
    type: image_target_type_t;
    imageFile: File;
    width: number;
    height: number;
    normalizationFactor: number;
    physicalHeightInM: string;
    topRadius: number;
    bottomRadius: number;
  };
  files: {
    filename: string;
    data: ArrayBuffer;
  }[];
}

export const Navigation: React.FC = () => {
  const nextRoute = useNextRoute();
  const previousRoute = usePreviousRoute();
  const currentRoute = useCurrentRoute();
  const [trainButtonEnabled] = useAtom(trainButtonEnabledAtom);
  const params = useTrainParameters();
  const [image] = useAtom(imageAtom);
  const [type] = useAtom(image_target_type_t_atom);
  const [isError] = useAtom(ERROR_ATOM);

  const handleNext = async () => {
    nextRoute();
  };

  React.useEffect(() => {
    if (currentRoute === Routes.Training) {
      trian();
    }
  }, [currentRoute]);

  function download(ab: ArrayBuffer) {
    const name = image.name + '.zpt';
    const blob = new Blob([ab], {type: 'application/octet-stream'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = url;
    a.download = name;
    a.click();
    URL.revokeObjectURL(url);
  }

  function getHeightValueFromWidthField(
    inputWidth: string,
    aspectRatio: number
  ) {
    // inputWidth will be of form '10mm' and we need to return the height including the units suffix
    const widthValue = Number(inputWidth.substring(0, inputWidth.length - 2));
    const widthUnits = inputWidth.substring(inputWidth.length - 2);
    const heightValue = widthValue / aspectRatio;
    return `${heightValue}${widthUnits}`;
  }

  function postMattercraft(ab: ArrayBuffer) {
    const name = image.name + '.zpt';
    const opener = window.opener || window.parent;

    opener.postMessage(
      {
        t: 'znewfile',
        source: 'framed-image-trainer',
        files: [
          {
            filename: name,
            data: ab,
          },
        ],
      } as FileGeneratorMessage,
      '*'
    );
  }

  function postDesigner(ab: ArrayBuffer) {
    const name = image.name + '.zpt';
    const opener = window.opener || window.parent;

    const inputIsHeight = typeof params.options.inputHeight !== 'undefined';
    const aspectRatio = image.width / image.height;

    const heightInputValue = inputIsHeight
      ? params.options.inputHeight
      : getHeightValueFromWidthField(params.options.inputWidth, aspectRatio);

    const units = heightInputValue.substring(heightInputValue.length - 2);
    const heightValue = Number(
      heightInputValue.substring(0, heightInputValue.length - 2)
    );

    const normalizationFactor =
      units === 'mm' ? heightValue * 0.001 : heightValue * 0.0254;

    const opts = parseOptions(params.options, {
      w: image.width,
      h: image.height,
    });

    const imageFile = new File([new Blob([image.buffer])], 'imageFile');
    opener.postMessage(
      {
        t: 'znewfile',
        source: 'framed-image-trainer',
        designerData: {
          type: type,
          imageFile: imageFile,
          width: image.width,
          height: image.height,
          normalizationFactor,
          physicalHeightInM: heightInputValue,
          topRadius: opts.topRadius,
          bottomRadius: opts.bottomRadius,
        },
        files: [
          {
            filename: name,
            data: ab,
          },
        ],
      } as FileGeneratorMessage,
      '*'
    );
  }

  async function trian() {
    const updatedOptions = {
      ...params.options,
      inputWidth: params.options.dpi ? undefined : params.options.inputWidth,
      inputHeight: params.options.dpi ? undefined : params.options.inputHeight,
    };

    const opener = window.opener || window.parent;
    const urlParams = new URLSearchParams(window.location.search);
    const platform = urlParams.get('platform')?.toLowerCase() || 'standalone';

    try {
      if (platform === 'designer') {
        opener.postMessage(
          {
            source: 'framed-image-trainer',
            t: 'trainingStart',
          },
          '*'
        );
      }
      const result = await train(params.image, updatedOptions);

      switch (platform) {
        case 'designer':
          postDesigner(result);
          break;
        case 'mattercraft':
          postMattercraft(result);
          break;
        default:
          download(result);
      }

      nextRoute();
    } catch (error) {
      console.error(error);
    }
  }

  const buttonTextMap = {
    [Routes.TargetTypeSelection]: 'Next',
    [Routes.TargetParameters]: 'Confirm',
  };
  const backButtonTextMap = {
    [Routes.TargetTypeSelection]: 'Cancel',
    [Routes.TargetParameters]: 'Back to type',
    [Routes.Training]: 'Back to type',
  };
  const show_back =
    currentRoute !== Routes.TargetTypeSelection && !isMattercraft();

  const nextButtonText = buttonTextMap[currentRoute];
  const backButtonText = backButtonTextMap[currentRoute];

  return (
    <NavigationContainer>
      <ThemedButton onClick={previousRoute} variant="inline" disabled={false}>
        <BackText>
          {show_back && (
            <>
              <span className="material-symbols-outlined">arrow_back_ios</span>
              {backButtonText}
            </>
          )}
        </BackText>
      </ThemedButton>

      {nextButtonText && (
        <ThemedButton
          onClick={handleNext}
          variant="primary"
          disabled={
            (!trainButtonEnabled || !!isError) &&
            currentRoute === Routes.TargetParameters
          }
        >
          {nextButtonText}
        </ThemedButton>
      )}
    </NavigationContainer>
  );
};
