/* eslint-disable tsdoc/syntax */
/* eslint-disable no-param-reassign */
// units to meters conversion
export var Unit;
(function (Unit) {
    Unit[Unit["px"] = -1] = "px";
    Unit[Unit["target"] = -2] = "target";
    Unit[Unit["mm"] = 0.001] = "mm";
    Unit[Unit["cm"] = 0.01] = "cm";
    Unit[Unit["in"] = 0.025399986284007407] = "in";
    Unit[Unit["m"] = 1] = "m";
})(Unit || (Unit = {}));
/**
 * Parses a {@link Units} into a value, unitType and multiplier required to convet to meters.
 * @param unit - A unit of measurement. Can be {@link Unit} or {@link MetricImperialUnitValue}
 * @returns An object containing the value, unitType and multiplier required to convet to meters.
 * @example
 * parseValue(`1.5cm`); //`{ value: 1.5, unitType: 'cm', multiplier: 0.01 }`
 * parseValue(`1m`); //`{ value: 1, unitType: 'm', multiplier: 1 }`
 */
export function parseValue(unit) {
    function throwError() {
        throw new Error(`Provided unit is not valid, must be one of: 'px', 'mm', 'cm', 'in', 'm'. For example '10mm'`);
    }
    if (typeof unit === "number") {
        unit = `${unit}target`;
    }
    const match = `${unit}`.split(/(target|px|mm|cm|in|m)/);
    if (!match[0] || !match[1])
        throwError();
    const value = Number.parseFloat(match[0]);
    const unitType = match[1];
    const multiplier = Unit[unitType];
    if (typeof multiplier !== "number")
        throwError();
    return {
        value,
        unitType,
        multiplier,
    };
}
/**
 * Returns a value in target units.
 * @param v - Value {@link Units}
 * @param imageHeight - Image height in pixels
 * @param dpi - DPI of the image
 * @returns Value in target units
 */
export function getValueInTargetUnits(v, imageHeight, dpi) {
    if (!v)
        return 0;
    const { value, multiplier, unitType } = parseValue(v);
    if (unitType === "target")
        return value;
    if (unitType === "px")
        return value / (0.5 * imageHeight);
    if (!dpi)
        throw new Error("You must specify inputWidth or inputHeight in physical units.");
    return (value * multiplier * dpi) / (Unit.in * 0.5 * imageHeight);
}
/**
 * Calculates getPhysicalScaleFactor from dpi and heightPixels.
 * @param dpi - The dpi of the image.
 * @param heightPixels - The height of the image in pixels.
 * @example
 * getPhysicalScaleFactor({ dpi: 110, heightPixels: 1100 }); // 0.12699993142003702
 * getPhysicalScaleFactor({ dpi: undefined, heightPixels: 1100 }); // -1
 */
export function getPhysicalScaleFactor(opts) {
    let physicalScaleFactor = -1;
    const { dpi, heightPixels } = opts;
    if (dpi && heightPixels) {
        physicalScaleFactor = (0.5 * heightPixels * Unit.in) / dpi;
    }
    return physicalScaleFactor;
}
/**
 * Calculates dpi from an image's physical dimensions.
 * @param inputWidth - Image width in {@link Units}
 * @param inputHeight - Image height in {@link Units}
 * @param pixelWidth - Pixel width of the image
 * @param pixelHeight - Pixel height of the image
 * @example
 * calculateDPI("25.4cm", "25.4cm", 1100, 1100); // 110
 * Units.calculateDPI("10in", "10in", 1100, 1100); // 110
 */
export function calculateDPI(inputWidth, inputHeight, pixelWidth, pixelHeight) {
    let dpi = 0;
    if (inputWidth) {
        const { value, multiplier } = parseValue(inputWidth);
        const realWorldWidthInMeters = value * multiplier;
        const realWorldWidthInInches = realWorldWidthInMeters / Unit.in;
        dpi = pixelWidth / realWorldWidthInInches;
    }
    else if (inputHeight) {
        const { value, multiplier } = parseValue(inputHeight);
        const realWorldHeightInMeters = value * multiplier;
        const realWorldHeightInInches = realWorldHeightInMeters / Unit.in;
        dpi = pixelHeight / realWorldHeightInInches;
    }
    else {
        throw new Error("Either width or height or dpi must be provided");
    }
    return dpi;
}
