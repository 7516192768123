import {atom} from 'jotai';

export enum Dimension {
  WIDTH = 'Width',
  HEIGHT = 'Height',
  UNKNOWN = 'Unknown',
}

export interface DimensionAndValue {
  dimension: Dimension;
  value: number;
}

export const dimensionAtom = atom<DimensionAndValue>({
  dimension: Dimension.HEIGHT,
  value: 100,
});

export enum UNIT {
  MM = 'mm',
  IN = 'in',
}

export const unit_atom = atom<UNIT>(UNIT.MM);
