import * as React from 'react';
import {styled} from './theme';
import {Parameters} from './sections/Parameters';
import {Preview} from './sections/preview/preview';
import {Splitter} from './sections/components/splitter';
import {TargetTypeSelection} from './sections/TargetTypeSelection';
import {Header} from './sections/header';
import {Navigation} from './sections/navigation';
import {H1, Loader, Dialog} from '@zappar/foundry-react-components';
import {Routes, useCurrentRoute} from './state';
import {ThemeDesigner} from './designer-theme';
import {useTheme} from 'styled-components';

const App = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ThemedApp = styled(App)`
  ${props => {
    return (
      props.theme === ThemeDesigner &&
      `font-size: 14px;
      font-weight: 400;
      font-style: normal;
      color: #344B60;
      line-height: 21px;
      `
    );
  }};
`;

// 1004x610
const MainContainer = styled.div`
  width: 1004px; // * 1.2 = 1204px
  height: 610px; // * 1.2 = 732px
  background-color: ${props => props.theme.colors.panelBackground};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const RoutesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
`;

const OptsSection = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 24px;
`;

interface StyledOverlayProps {
  visible: boolean;
}

const StyledOverlay = styled.div<StyledOverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const FinishedMessage: React.FC = () => {
  return (
    <Dialog
      className="finished-dialog"
      title="IMAGE TRAINING COMPLETE"
      description="Image has been trained and you may now track content to it. Please close this window to continue."
    />
  );
};

export const FoundryImageTrainer: React.FC = () => {
  const currentRoute = useCurrentRoute();
  const theme = useTheme();
  const loader = currentRoute === Routes.Training ? <Loader /> : null;
  const done = currentRoute === Routes.Finished ? <FinishedMessage /> : null;

  return (
    <ThemedApp>
      <MainContainer>
        <StyledOverlay
          visible={
            loader !== null || (theme !== ThemeDesigner && done !== null)
          }
        >
          {loader}
          {done}
        </StyledOverlay>
        <Splitter
          leftElement={
            <OptsSection>
              <Header />
              <RoutesContainer className="routes">
                <TargetTypeSelection />
                <Parameters />
              </RoutesContainer>
              <Navigation />
            </OptsSection>
          }
          rightElement={<Preview />}
        />
      </MainContainer>
    </ThemedApp>
  );
};
