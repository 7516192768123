import {PerformOptions, TrainOptions} from '@zappar/imagetraining/lib/options';
import * as Units from '@zappar/imagetraining/lib/units';

type InternalOptions = Omit<
  TrainOptions,
  'radius' | 'inputWidth' | 'inputHeight' | 'dpi' | 'circumference'
> & {physicalScaleFactor: number};

// Adaptation of parseOptions which avoids reading file. Params aren't the cleanest. TODO: Refactor
export function parseOptions(
  options: Partial<TrainOptions>,
  imageDimensions: {w: number; h: number}
) {
  const opts: InternalOptions = {
    maxWidth: options?.maxWidth ?? 500,
    maxHeight: options?.maxHeight ?? 500,
    excludePreview: options?.excludePreview ?? false,
    topRadius: 0,
    bottomRadius: 0,
    sideLength: 0,
    physicalScaleFactor: -1,
    onTrainProgress: options?.onTrainProgress,
  };

  if (
    !checkOnlyOneSet(options?.inputWidth, options?.inputHeight, options?.dpi)
  ) {
    throw new Error('Set only one of inputWidth, inputHeight or dpi');
  }

  if (
    !checkOnlyOneSet(
      options?.radius,
      options?.circumference,
      options?.topRadius
    )
  ) {
    throw new Error('Set only one of radius, topRadius, or circumference');
  }

  if (
    !checkOnlyOneSet(
      options?.radius,
      options?.circumference,
      options?.bottomRadius
    )
  ) {
    throw new Error('Set only one of radius, bottomRadius, or circumference');
  }

  const width = imageDimensions.w;
  const height = imageDimensions.h;

  if (!width || !height)
    throw new Error('Unable to discover width and height of image');
  if (width < 128 || height < 128)
    throw new Error('Image too small (width and/or height < 128px)');
  if (width > 2048 || height > 2048)
    throw new Error('Image too large (width and/or height > 2048px)');

  if (!options?.dpi && (options?.inputWidth || options?.inputHeight)) {
    options.dpi = Units.calculateDPI(
      options.inputWidth,
      options.inputHeight,
      width,
      height
    );
  }

  opts.physicalScaleFactor = Units.getPhysicalScaleFactor({
    dpi: options?.dpi,
    heightPixels: height,
  });

  const _sideLength = Units.getValueInTargetUnits(
    options?.sideLength,
    height,
    options?.dpi
  );
  const radius = Units.getValueInTargetUnits(
    options?.radius,
    height,
    options?.dpi
  );
  const _topRadius = Units.getValueInTargetUnits(
    options?.topRadius,
    height,
    options?.dpi
  );
  const _bottomRadius = Units.getValueInTargetUnits(
    options?.bottomRadius,
    height,
    options?.dpi
  );
  const circumference = Units.getValueInTargetUnits(
    options?.circumference,
    height,
    options?.dpi
  );

  opts.topRadius = _topRadius || radius || circumference / (2 * Math.PI) || 0;
  opts.bottomRadius =
    _bottomRadius || radius || circumference / (2 * Math.PI) || 0;
  opts.sideLength = _sideLength || 0;

  const {
    maxWidth,
    maxHeight,
    topRadius,
    bottomRadius,
    sideLength,
    physicalScaleFactor,
    onTrainProgress,
  } = opts;

  return {
    maxWidth,
    maxHeight,
    topRadius,
    bottomRadius,
    sideLength,
    physicalScaleFactor,
    onTrainProgress,
  };
}

function checkOnlyOneSet(...args: (unknown | undefined)[]): boolean {
  let foundOne = false;
  for (const a of args) {
    if (a !== undefined) {
      if (foundOne) return false; // More than one set
      foundOne = true;
    }
  }
  return true;
}
