import {
  H3,
  InputForType,
  RadioSwitch,
  RadioSwitcher,
  Select,
  StyledInput,
} from '@zappar/foundry-react-components';
import {useAtom} from 'jotai';
import * as React from 'react';
import {styled} from '../../theme';
import {UNIT, unit_atom} from '../dimensions/state';
import {Tooltip} from './tooltip';
import {ThemeDesigner} from '../../designer-theme';

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${props =>
    props.theme === ThemeDesigner &&
    `
margin-bottom: 8px;
  `}
`;

const ThemedH3 = styled(H3)`
  ${props =>
    props.theme === ThemeDesigner &&
    `
  font-size: 14px;
  color: #344B60;
  text-transform: none;
  // margin-top: 16px;
  `}
`;
const ThemedSelect = styled(Select)`
  ${props =>
    props.theme === ThemeDesigner &&
    `
border: 1px solid #B2C4D7;
border-radius: 8px;
box-sizing: border-box;
padding: 11px 16px 9px 20px;
height: 40px;
appearance: none;
background: no-repeat calc(100% - 15px) url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='16' fill='%23344B60' viewBox='0 0 16 16' width='16'><path d='M4.22754 6.4709L4.28294 6.51718L8.0001 10.2344L11.7173 6.51718C11.8735 6.36097 12.1267 6.36097 12.2829 6.51718C12.4218 6.65603 12.4372 6.87157 12.3292 7.02747L12.2829 7.08287L8.28294 11.0829C8.23087 11.1349 8.16802 11.1697 8.10157 11.187L8.03419 11.1986H7.96601C7.89796 11.1928 7.83111 11.1697 7.77265 11.1292L7.71725 11.0829L3.71725 7.08287C3.56105 6.92666 3.56105 6.67339 3.71725 6.51718C3.83875 6.39569 4.01896 6.36869 4.16655 6.43618L4.22754 6.4709Z' /></svg>");
  `}
`;

const ThemedInputForType = styled(InputForType)`
  ${props =>
    props.theme === ThemeDesigner &&
    `
  border: 1px solid #B2C4D7;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 11px 16px 9px 20px;
  height: 40px;
  `}
`;

interface CommonProps<T> {
  title?: string;
  tooltip?: string;
  value: T;
  onChange: (value: any) => void;
}

interface NumericInputProps extends CommonProps<number> {
  showUnit?: boolean;
}

interface SelectProps extends CommonProps<string> {
  options: string[];
}

export const NumericInput: React.FC<NumericInputProps> = props => {
  const [unit] = useAtom(unit_atom);

  let unitString = '';

  if (props.showUnit) unitString = unit === UNIT.IN ? ' (in)' : ' (mm)';

  return (
    <StyledInputContainer>
      <StyledTitleContainer>
        <ThemedH3>
          {props.title}
          {unitString}
        </ThemedH3>
        {props.tooltip && <Tooltip text={props.tooltip} />}
      </StyledTitleContainer>

      <ThemedInputForType
        default={props.value}
        onInstantValue={values => {}}
        onCommit={values => {
          props.onChange(values.value);
        }}
        valuesByNode={{
          value: props.value,
        }}
        type={{name: 'number'}}
      />
    </StyledInputContainer>
  );
};

export const SelectInput: React.FC<SelectProps> = props => {
  return (
    <StyledInputContainer>
      <StyledTitleContainer>
        <ThemedH3>{props.title}</ThemedH3>
        {props.tooltip && <Tooltip text={props.tooltip} />}
      </StyledTitleContainer>

      <ThemedSelect
        value={props.value}
        onChange={value => props.onChange(value.target.value)}
      >
        {props.options.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </ThemedSelect>
    </StyledInputContainer>
  );
};

interface RadioInputProps extends CommonProps<string> {
  options: string[];
}

const StyledRadioInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: ${({theme}) => theme.colors.fontColor};
`;

// hacky fix.
export const StyledRadioSwitcher = styled(RadioSwitcher)`
  & > * {
    cursor: pointer;
  }
`;

export const RadioInput: React.FC<RadioInputProps> = props => {
  const [selectedOption, setSelectedOption] = React.useState(props.value);

  const options = props.options.map(option => (
    <RadioSwitch
      key={option}
      onChecked={() => {
        setSelectedOption(option);
        props.onChange && props.onChange(option);
      }}
      checked={option === selectedOption}
      icon={<>{option}</>}
    />
  ));

  return (
    <StyledInputContainer>
      <StyledRadioInputContainer>
        <StyledRadioSwitcher>{options}</StyledRadioSwitcher>
      </StyledRadioInputContainer>
    </StyledInputContainer>
  );
};

export const StyledInputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding-bottom: 4px;
`;

export const StyledInputFiller = styled.div`
  display: flex;
  width: 100%;
`;
