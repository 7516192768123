// eslint-disable-next-line node/no-unpublished-import
import React from 'react';
import {Canvas} from '@react-three/fiber';
import {OrbitControls, Sky, Stage} from '@react-three/drei';
import {TargetImagePreviewMesh, boundingBoxWorldPositionAtom} from './mesh';
import {UserUploadedMaterial} from './material';
import {useGetPreviewOptions} from '../../state';
import {useAtom} from 'jotai';

interface Props {
  children?: React.ReactNode;
}

const style: React.CSSProperties = {
  borderRadius: '0 8px 8px 0',
};
// eslint-disable-next-line node/no-unsupported-features/node-builtins
const hdrPath = new URL('../../assets/potsdamer_platz_1k.hdr', import.meta.url);

export const Preview: React.FC<Props> = () => {
  const options = useGetPreviewOptions();
  const worldPosition = useAtom(boundingBoxWorldPositionAtom)[0];

  return (
    <Canvas style={style} shadows camera={{near: Number.MIN_VALUE}}>
      <Sky
        rayleigh={0}
        distance={450000}
        sunPosition={[0, 1, 0]}
        inclination={0}
        azimuth={180}
      />
      {options && (
        <Stage
          adjustCamera
          shadows
          preset={'rembrandt'}
          environment={{
            files: hdrPath.toString(),
          }}
        >
          <OrbitControls
            enableDamping
            minPolarAngle={Math.PI / 4}
            maxPolarAngle={Math.PI / 2}
            maxDistance={100}
            enableZoom={true}
            minDistance={0.001}
            target={worldPosition}
          />
          <TargetImagePreviewMesh options={options}>
            <UserUploadedMaterial />
          </TargetImagePreviewMesh>
        </Stage>
      )}
    </Canvas>
  );
};
