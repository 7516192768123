import React from 'react';
import ReactDOM from 'react-dom/client';
import {FoundryImageTrainer} from './index_foundry';
import {GlobalStyles} from '@zappar/foundry-react-components/lib/config/global.styles';
import {themes} from '@zappar/foundry-react-components/lib/theme';
// provider
import {ThemeProvider} from 'styled-components';
import {ThemeDesigner} from './designer-theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export function isMattercraft() {
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const urlParams = new URLSearchParams(window.location.search);
  const platform = urlParams.get('platform')?.toLowerCase() === 'mattercraft';
  return platform;
}

function getTheme() {
  const [dark, light] = themes;
  // https://imagetrainer-staging.zap.works/?theme=studio-light
  // or
  // https://imagetrainer-staging.zap.works/?theme=studio-dark
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const urlParams = new URLSearchParams(window.location.search);
  const theme = urlParams.get('theme');
  if (theme === 'studio-dark') {
    return dark;
  } else if (theme === 'studio-light') {
    return light;
  } else if (theme === 'designer') {
    return ThemeDesigner;
  } else {
    // standalone/designer?
    return dark;
  }
}

root.render(
  <React.StrictMode>
    <ThemeProvider theme={getTheme()}>
      <GlobalStyles />
      <FoundryImageTrainer />
    </ThemeProvider>
  </React.StrictMode>
);
