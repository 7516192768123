import {H3} from '@zappar/foundry-react-components';
import React, {createContext, useContext, useState, FC} from 'react';
import {styled} from '../../theme';
import {ThemeDesigner} from '../../designer-theme';

type CardContextType = [number, (value: number) => void];
const CardContext = createContext<CardContextType | null>(null);

function useCardContext(): CardContextType {
  const context = useContext(CardContext);
  if (!context) {
    throw new Error(
      'Card compound components cannot be rendered outside the CardGroup component'
    );
  }
  const [state, setState] = context;
  return [state || 0, setState];
}

interface CardGroupProps {
  defaultValue?: number;
  onChange?: (value: number) => void;
  children: React.ReactNode;
}

const CardGroup: FC<CardGroupProps> & {Card: typeof Card} = ({
  children,
  defaultValue = 0,
  onChange,
}) => {
  const [state, setState] = useState<number>(defaultValue);

  function handleOnChange(value: number) {
    setState(value);
    if (onChange) {
      onChange(value);
    }
  }

  return (
    <CardContext.Provider value={[state, handleOnChange]}>
      <CardContainer>{children}</CardContainer>
    </CardContext.Provider>
  );
};

interface CardProps {
  value: number;
  title: string;
  description: string;
  image: React.ReactNode;
  children?: React.ReactNode;
}

const Card: FC<CardProps> = ({value, title, description, image}) => {
  const [state, onChange] = useCardContext();
  const active = value === state;
  return (
    <StyledCard active={active} onClick={() => onChange(value)}>
      <ImageWrapper>{image}</ImageWrapper>
      <DescriptionWrapper>
        <ThemedH3 active={active}>{title}</ThemedH3>
        <>{description}</>
      </DescriptionWrapper>
    </StyledCard>
  );
};

const ThemedH3 = styled(H3)<{active: boolean}>`
  ${props => {
    return (
      props.theme === ThemeDesigner &&
      `font-size: 20px;
      font-weight: 700;
      font-style: normal;
      color: ${props.active ? '#4a90e2' : '#344B60'};
      line-height: 24px;
      margin-bottom: 12px;
      text-transform: none;
      `
    );
  }};
`;

const CardContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const ImageWrapper = styled.div`
  width: 233px;
  height: 142px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* round top */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const StyledCard = styled.div<{active: boolean}>`
  width: 233px;
  height: 268px;
  background-color: ${props =>
    props.active
      ? props.theme.colors.buttonPrimaryColor
      : props.theme.colors.panelBackground};
  color: ${props =>
    props.active
      ? props.theme.colors.buttonFontColor
      : props.theme.colors.fontColor};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 1px 5px 0px #0000001a;
  /* if inactive, hover outline */
  &:hover {
    outline: ${props =>
      props.active
        ? 'none'
        : `2px solid ${props.theme.colors.buttonPrimaryColor}`};
  }
`;

CardGroup.Card = Card;

export {CardGroup, Card};
